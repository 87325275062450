import { RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { isValidJsonString } from 'common/helpers';
import dayjs from 'dayjs';
import { useState } from 'react';

import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import { FieldTypes } from '@/types';

const isNumeric = (type: FieldTypes) =>
  [FieldTypes.CURRENCY, FieldTypes.PERCENTAGE, FieldTypes.INTEGER].includes(
    type
  );

const isDate = (type: FieldTypes) => {
  return [FieldTypes.DATE].includes(type);
};

const ops = [
  { id: 'eq', label: 'Equals' },
  { id: 'neq', label: 'Not equals' },
  { id: 'gt', label: 'Greater than', validate: isNumeric },
  { id: 'lt', label: 'Less than', validate: isNumeric },
  { id: 'gte', label: 'Greater than or equals', validate: isNumeric },
  { id: 'lte', label: 'Less than or equals', validate: isNumeric },
  // { id: 'in', label: 'In' },
  // { id: 'nin', label: 'Not in' },
  { id: 'contains', label: 'Contains' },
  { id: 'ncontains', label: 'Not contains' },
  { id: 'containedin', label: 'Contained in' },
  { id: 'ncontainedin', label: 'Not contained in' },
  { id: 'startswith', label: 'Starts with' },
  { id: 'endswith', label: 'Ends with' },
  { id: 'before', label: 'Before', validate: isDate },
  { id: 'beforeEquals', label: 'Before or equals', validate: isDate },
  { id: 'after', label: 'After', validate: isDate },
  { id: 'afterEquals', label: 'After or equals', validate: isDate },
  { id: 'isEmpty', label: 'Is empty' },
  // { id: 'regex', label: 'Regex' },
  // { id: 'nregex', label: 'Not regex' },
  // { id: 'string-distance', label: 'Similarity score' },
  // { id: 'custom', label: 'Custom' },
];

const FieldMatcher = ({
  fields,
  value: fieldsMatchers = [],
  setValue,
  addLabel = 'Add',
  hideUsePolicyData = false,
}: {
  fields: any[];
  value: any[];
  setValue: (val: any) => void;
  addLabel?: string;
  hideUsePolicyData?: boolean;
}) => {
  const [field, setField] = useState<{ type: FieldTypes }>();

  const getSelectedField = (fieldId) => {
    return fields.find((f) => f.id === fieldId);
  };

  return (
    <Box>
      {fieldsMatchers?.map((fieldMatcher, i) => (
        <Box key={i} sx={{ mt: 1, display: 'flex', flexWrap: 'wrap' }}>
          <FormControl sx={{ mr: 1, mb: 1 }}>
            <InputLabel id={fieldMatcher.id}>Field</InputLabel>
            <Select
              label="Field"
              labelId={fieldMatcher.id}
              value={fieldMatcher.field}
              onChange={(e) => {
                const newFieldMatchers = [...fieldsMatchers];
                newFieldMatchers[i].field = e.target.value;
                setField(getSelectedField(e.target.value));
                setValue(newFieldMatchers);
              }}
              sx={{ minWidth: 90 }}
            >
              {fields
                ?.sort((a, b) => (b.label > a.label ? -1 : 1))
                ?.map((field) => (
                  <MenuItem key={field.id} value={field.id}>
                    {field.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl sx={{ mr: 1, mb: 1 }}>
            <InputLabel>Operation</InputLabel>
            <Select
              label="Operation"
              value={fieldMatcher.op}
              onChange={(e) => {
                const newFieldMatchers = [...fieldsMatchers];
                newFieldMatchers[i].op = e.target.value;
                setValue(newFieldMatchers);
              }}
              sx={{ minWidth: 90 }}
            >
              {ops.map((op) => {
                if (op.validate && field && !op.validate(field?.type)) {
                  return null;
                }
                return (
                  <MenuItem key={op.id} value={op.id}>
                    {op.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {getSelectedField(fieldMatcher?.field)?.type === FieldTypes.DATE ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <BasicDatePicker
                label="Value"
                value={fieldMatcher.value}
                setValue={(value) => {
                  const newFieldMatchers = [...fieldsMatchers];
                  newFieldMatchers[i].value = dayjs(value)
                    .utc()
                    .format('YYYY-MM-DD');
                  setValue(newFieldMatchers);
                }}
              />
            </LocalizationProvider>
          ) : (
            <TextField
              label="Value"
              value={fieldMatcher.value}
              onChange={(e) => {
                const newFieldMatchers = [...fieldsMatchers];
                newFieldMatchers[i].value = e.target.value;
                setValue(newFieldMatchers);
              }}
              sx={{ mr: 1, minWidth: 90 }}
              error={
                ['ncontainedin', 'containedin'].includes(fieldMatcher.op) &&
                !isValidJsonString(fieldMatcher.value)
              }
            />
          )}
          <FormControl sx={{ mr: 1, mb: 1 }}>
            <InputLabel>Case sensitivity</InputLabel>
            <Select
              label="Case sensitivity"
              value={fieldMatcher.caseSensitive}
              onChange={(e) => {
                const newFieldMatchers = [...fieldsMatchers];
                newFieldMatchers[i].caseSensitive = e.target.value;
                setValue(newFieldMatchers);
              }}
              sx={{ minWidth: 90 }}
            >
              <MenuItem value="">&nbsp;</MenuItem>
              {[
                { id: 'true', label: 'True', value: true },
                { id: 'false', label: 'False', value: false },
              ].map((e) => (
                <MenuItem key={e.id} value={(e.value as any) ?? e.id}>
                  {e.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!hideUsePolicyData && (
            <FormControl sx={{ mr: 1, mb: 1 }}>
              <InputLabel>Use policy data</InputLabel>
              <Select
                label="Use policy data"
                value={fieldMatcher.usePolicyData}
                onChange={(e) => {
                  const newFieldMatchers = [...fieldsMatchers];
                  newFieldMatchers[i].usePolicyData = e.target.value;
                  setValue(newFieldMatchers);
                }}
                sx={{ minWidth: 90 }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {[
                  { id: 'true', label: 'True', value: true },
                  { id: 'false', label: 'False', value: false },
                ].map((e) => (
                  <MenuItem key={e.id} value={(e.value as any) ?? e.id}>
                    {e.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl sx={{ mr: 1, mb: 1 }}>
            <InputLabel>Skip empty</InputLabel>
            <Select
              label="Skip empty"
              value={fieldMatcher.skipEmpty}
              onChange={(e) => {
                const newFieldMatchers = [...fieldsMatchers];
                newFieldMatchers[i].skipEmpty = e.target.value;
                setValue(newFieldMatchers);
              }}
              sx={{ minWidth: 90 }}
            >
              <MenuItem value="">&nbsp;</MenuItem>
              {[
                { id: 'true', label: 'True', value: true },
                { id: 'false', label: 'False', value: false },
              ].map((e) => (
                <MenuItem key={e.id} value={(e.value as any) ?? e.id}>
                  {e.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton
            onClick={() => {
              const newFieldMatchers = [...fieldsMatchers];
              newFieldMatchers.splice(i, 1);
              setValue(newFieldMatchers);
            }}
          >
            <RemoveCircleOutline />
          </IconButton>
        </Box>
      ))}
      <Button
        onClick={() => setValue([...fieldsMatchers, {}])}
        sx={{ mt: 0.5 }}
      >
        {addLabel}
      </Button>
    </Box>
  );
};

export default FieldMatcher;
