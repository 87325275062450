import { useContext, useState } from 'react';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import { LoadingContext } from 'contexts/LoadingContext';

import API from '@/services/API';

const WidgetSelector = ({
  sharedWidgets,
  adminSelectedWidget,
  producerSelectedWidget,
}) => {
  const [dashboardWidgetsAdmin, setDashboardWidgetsAdmin] = useState(
    adminSelectedWidget ?? []
  );
  const [dashboardWidgetsProducer, setDashboardWidgetsProducer] = useState(
    producerSelectedWidget ?? []
  );
  const { setLoadingConfig } = useContext(LoadingContext);

  const saveWidgetSettingPoster = API.getMutation(
    'insights/accountWidgetsSettings',
    'POST'
  );

  const saveWidgetSetting = async () => {
    setLoadingConfig({
      loading: true,
      message: 'Saving widget setting...',
    });
    const data = {
      adminWidgetsSettings: [...legacyAdminWidgets, ...dashboardWidgetsAdmin],
      producerWidgetsSettings: [
        ...legacyProducerWidgets,
        ...dashboardWidgetsProducer,
      ],
    };
    await saveWidgetSettingPoster.mutateAsync(data);
    setLoadingConfig({
      loading: false,
    });
  };

  const legacyAdminWidgets = dashboardWidgetsAdmin?.filter((x) => {
    return isNaN(x) || x === '';
  });
  const legacyProducerWidgets = dashboardWidgetsProducer?.filter((x) => {
    return isNaN(x) || x === '';
  });

  const handleFieldChange = (value, name) => {
    if (name === 'admin') {
      setDashboardWidgetsAdmin(value);
    } else {
      setDashboardWidgetsProducer(value);
    }
  };

  return (
    <Box sx={{ padding: 2, minWidth: '400px' }}>
      <Typography variant="h6">Choose shared widgets</Typography>
      <Box padding={2}>
        <Typography variant="caption">Admin</Typography>
        <Select
          value={dashboardWidgetsAdmin}
          style={{ width: '100%' }}
          label="Widgets"
          multiple
          onChange={(e) => handleFieldChange(e.target.value, 'admin')}
        >
          {sharedWidgets?.map((field) => (
            <MenuItem key={field.id} value={field.id}>
              {field.name}
            </MenuItem>
          ))}
        </Select>
        <Typography variant="caption">Producer</Typography>

        <Select
          value={dashboardWidgetsProducer}
          label="Widgets"
          style={{ width: '100%' }}
          multiple
          onChange={(e) => handleFieldChange(e.target.value, 'producer')}
        >
          {sharedWidgets?.map((field) => (
            <MenuItem key={field.id} value={field.id}>
              {field.name}
            </MenuItem>
          ))}
        </Select>

        <Button
          variant="contained"
          onClick={saveWidgetSetting}
          style={{ marginTop: '10px' }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default WidgetSelector;
