import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { SyntheticEvent, useState } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const DataVisualizationTabs = ({
  tabOneLabel,
  tabOneComponent,
  tabTwoLabel,
  tabTwoComponent,
  tabThreeLabel,
  tabThreeComponent,
}: any) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Tabs orientation="vertical" value={value} onChange={handleChange}>
        <Tab label={tabOneLabel} {...a11yProps(0)} />
        <Tab label={tabTwoLabel} {...a11yProps(1)} />
        <Tab label={tabThreeLabel} {...a11yProps(2)} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        {tabOneComponent}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {tabTwoComponent}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {tabThreeComponent}
      </CustomTabPanel>
    </Box>
  );
};

export default DataVisualizationTabs;
