import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CellValueChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { cloneDeep, isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useOutletContext, useSearchParams } from 'react-router-dom';
import CommonFormatter from 'common/Formatter';

import LoadingCircle from '@/components/atoms/LoadingCircle';
import MultiSelect from '@/components/molecules/MultiSelect';
import SearchBox from '@/components/molecules/SearchBox';
import API from '@/services/API';
import { useRoleStore } from '@/store';
import { DateRange, DateRangesTypes, Roles } from '@/types';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import SearchSettings from '@/components/molecules/SearchSettings';
import CompGridBulkActions from './CompGridBulkActions';

const getGridLevelCols = (
  name: string,
  rate_fields: string[],
  role: Roles | null,
  editable: boolean,
  editedCells: any = {}
) => {
  if (role === Roles.PRODUCER) {
    return [
      {
        field: `rate::${name}`,
        headerName: 'Rate',
        type: 'numericColumn',
        width: 80,
        cellStartedEdit: editable,
        editable,
        wrapHeaderText: true,
        cellClassRules: {
          'ag-cell-custom-valid': (params) =>
            editedCells[`${params.data.id}-${params.colDef.field}`] === 'valid',
          'ag-cell-custom-invalid': (params) =>
            editedCells[`${params.data.id}-${params.colDef.field}`] ===
            'invalid',
        },
      },
    ];
  }
  return [
    {
      field: `carrier_rate::${name}`,
      headerName: 'Carrier rate',
      type: 'numericColumn',
      width: 80,
      cellStartedEdit: editable,
      editable,
      wrapHeaderText: true,
      hide: !rate_fields.includes('carrier_rate'),
      cellClassRules: {
        'ag-cell-custom-valid': (params) =>
          editedCells[`${params.data.id}-${params.colDef.field}`] === 'valid',
        'ag-cell-custom-invalid': (params) =>
          editedCells[`${params.data.id}-${params.colDef.field}`] === 'invalid',
      },
    },
    {
      field: `house_rate::${name}`,
      headerName: 'House rate',
      type: 'numericColumn',
      width: 80,
      cellStartedEdit: editable,
      editable,
      wrapHeaderText: true,
      hide: !rate_fields.includes('house_rate'),
      cellClassRules: {
        'ag-cell-custom-valid': (params) =>
          editedCells[`${params.data.id}-${params.colDef.field}`] === 'valid',
        'ag-cell-custom-invalid': (params) =>
          editedCells[`${params.data.id}-${params.colDef.field}`] === 'invalid',
      },
    },
    {
      field: `rate::${name}`,
      headerName: 'Total rate',
      type: 'numericColumn',
      width: 80,
      cellStartedEdit: editable,
      editable,
      wrapHeaderText: true,
      hide: !rate_fields.includes('total_rate'),
      cellClassRules: {
        'ag-cell-custom-valid': (params) =>
          editedCells[`${params.data.id}-${params.colDef.field}`] === 'valid',
        'ag-cell-custom-invalid': (params) =>
          editedCells[`${params.data.id}-${params.colDef.field}`] === 'invalid',
      },
    },
  ];
};

const CompGridsView = () => {
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);

  const viewSettings = accountSettings?.pages_settings?.comp_grids_schedules;
  let pageLabel = 'Comp grids';
  if (viewSettings?.page_label) {
    pageLabel = viewSettings?.page_label;
  }
  if (isFetchedAccountSettings && viewSettings?.show_page === false) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
    );
  }

  const [searchParams, setSearchParams] = useSearchParams({});
  const [selectedCompGrids, setSelectedCompGrids] = useState<number[]>([]);
  const [filteredLevels, setFilteredLevels] = useState<string[]>([]);
  const [editable, setEditable] = useState(false);
  const [editedCells, setEditedCells] = useState({});
  const [editedValues, setEditedValues] = useState({});
  const [editNode, setEditNode] = useState<{ [key: string]: any[] }>({});
  const [loading, setLoading] = useState(false);
  const [openBulkActionsDialog, setOpenBulkActionsDialog] = useState(false);
  const [selectedDateRanges, setSelectedDateRanges] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
  const [selectedRatesIds, setSelectedRatesIds] = useState<string[]>([]);
  const [selectedCriteria, setSelectedCriteria] = useState<any[]>([]);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState<string>(
    searchParams.get('effective_date')
      ? 'singleDate'
      : searchParams.get('range_date')
        ? 'dateRange'
        : 'today'
  );
  const [showDateRanges, setShowDateRanges] = useState<boolean>(
    searchParams.get('incl_date_ranges') ? true : false
  );
  const [effectiveDateFilterValue, setEffectiveDateFilterValue] = useState<
    Date | undefined
  >(
    searchParams.get('effective_date')
      ? new Date(searchParams.get('effective_date')!)
      : undefined
  );

  const [dateRangeFilterValue, setDateRangeFilterValue] = useState<
    number | undefined
  >(
    searchParams.get('range_date')
      ? parseInt(searchParams.get('range_date')!)
      : undefined
  );

  const gridRef = useRef<any>(null);
  const { openSnackbar } = useOutletContext<any>();
  const query = searchParams.get('q');
  const inclDateRanges = searchParams.get('incl_date_ranges');

  const { userRole } = useRoleStore();
  const isProducer = userRole === Roles.PRODUCER;

  const toISOStringSafe = (dateValue: any): string | undefined => {
    const date = new Date(dateValue);
    return !isNaN(date.getTime()) ? date.toISOString() : undefined;
  };

  const queryString = `effective_date=${toISOStringSafe(effectiveDateFilterValue)}&range_date=${dateRangeFilterValue}`;

  const {
    data: compGrids,
    isLoading: isLoadingCompGrids,
    refetch: refetchCompGrids,
  } = API.getBasicQuery('comp-grids/view', queryString);

  const { data: dateRangesData } = API.getBasicQuery(
    'date-ranges',
    `type=${DateRangesTypes.ANY}${selectedCompGrids?.length && selectedCompGrids?.length !== compGrids?.length ? `&comp_grids=${selectedCompGrids.join(',')}` : ''}`
  );
  const ratesPatcher = API.getMutation('comp-grids/rates', 'PATCH');
  const ratesBulkPatcher = API.getMutation(
    'comp-grids/rates/bulk-edit',
    'PATCH'
  );
  const ratesBulkDeleter = API.getMutation(
    'comp-grids/rates/bulk-edit',
    'DELETE'
  );
  const copyRatesPoster = API.getMutation(
    'comp-grids/rates/copy-rates',
    'POST'
  );

  const columnDefsBase = useMemo(
    () => [
      {
        headerCheckboxSelection: editable,
        checkboxSelection: editable,
        hide: !editable,
        width: 5,
        pinned: 'left' as const,
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
          marginLeft: 12,
          border: 'none',
        },
      },
      { field: 'id', hide: true },
      {
        field: 'company',
        headerName: 'Carrier',
        pinned: 'left' as const,
        width: 140,
        tooltipValueGetter: (p) => p?.data?.company,
      },
      {
        field: 'product_type',
        headerName: 'Product type',
        pinned: 'left' as const,
        width: 90,
        wrapHeaderText: true,
      },
      {
        field: 'product',
        headerName: 'Product',
        pinned: 'left' as const,
        width: 180,
        tooltipValueGetter: (p) => p?.data?.product,
      },
      {
        field: 'policy_year_start',
        headerName: 'Policy year start',
        pinned: 'left' as const,
        width: 55,
        wrapHeaderText: true,
      },
      {
        field: 'policy_year_end',
        headerName: 'Policy year end',
        pinned: 'left' as const,
        width: 55,
        wrapHeaderText: true,
      },
      {
        field: 'issue_age_start',
        headerName: 'Age start',
        pinned: 'left' as const,
        width: 55,
        wrapHeaderText: true,
      },
      {
        field: 'issue_age_end',
        headerName: 'Age\nend',
        pinned: 'left' as const,
        width: 55,
        wrapHeaderText: true,
      },
      {
        field: 'premium_min',
        headerName: 'Premium min',
        pinned: 'left' as const,
        width: 55,
        wrapHeaderText: true,
      },
      {
        field: 'premium_max',
        headerName: 'Premium max',
        pinned: 'left' as const,
        width: 55,
        wrapHeaderText: true,
      },
      {
        field: 'compensation_type',
        headerName: 'Compensation type',
        pinned: 'left' as const,
        width: 100,
        wrapHeaderText: true,
        tooltipValueGetter: (p) => p?.data?.compensation_type,
      },
      {
        field: 'rates_date_ranges',
        headerName: 'Date range',
        pinned: 'left' as const,
        width: 220,
        autoHeight: true,
        hide: !showDateRanges,
        cellRenderer: (params) => {
          const { value } = params;
          if (!value) return null;
          return (
            <Box display="flex" flexDirection="column">
              {value.map((dateRange, index) => (
                <Tooltip title={dateRange} arrow>
                  <Chip
                    key={`${index}-datechip`}
                    sx={{ m: 0.25 }}
                    label={dateRange}
                  />
                </Tooltip>
              ))}
            </Box>
          );
        },
      },
    ],
    [editable, showDateRanges]
  );

  const searchSettings = [
    {
      id: 'incl_date_ranges',
      type: 'toggle',
      label: 'Show date ranges',
    },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newInclDateRanges = searchParams.get('incl_date_ranges');

    setShowDateRanges(newInclDateRanges ? true : false);
  }, [location.search, inclDateRanges]);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    const updateParams = (key: string, value: any) => {
      if (value) {
        params.set(key, toISOStringSafe(value) || '');
      } else {
        params.delete(key);
      }
    };

    if (selectedDateFilter === 'singleDate') {
      setDateRangeFilterValue(undefined);
      params.delete('range_date');
    } else if (selectedDateFilter === 'dateRange') {
      setEffectiveDateFilterValue(undefined);
      params.set('range_date', dateRangeFilterValue?.toString() || '');
      params.delete('effective_date');
    } else {
      setEffectiveDateFilterValue(undefined);
      setDateRangeFilterValue(undefined);
      params.delete('effective_date');
      params.delete('range_date');
    }

    updateParams('effective_date', effectiveDateFilterValue);

    setSearchParams(params);
  }, [selectedDateFilter, effectiveDateFilterValue, dateRangeFilterValue]);

  const filteredCompGrids = useMemo(
    () =>
      (Array.isArray(compGrids) ? compGrids : []).filter((compGrid) =>
        selectedCompGrids.includes(compGrid.id)
      ),
    [compGrids, selectedCompGrids]
  );

  const compGridLevels = useMemo(() => {
    const uniqueLevels = [
      ...new Set(
        filteredCompGrids.map((compGrid) => compGrid?.comp_grid_levels).flat()
      ),
    ];

    // Add comp grid available rate fields to each level for showing/hiding rate columns
    return uniqueLevels.map((level) => {
      const correspondingCompGrid = filteredCompGrids.find((compGrid) =>
        compGrid?.comp_grid_levels.includes(level)
      );

      return {
        ...level,
        rate_fields: correspondingCompGrid?.rate_fields || [
          'carrier_rate',
          'house_rate',
          'total_rate',
        ],
      };
    });
  }, [filteredCompGrids]);

  // The following assumes that all levels have the same children
  const levelColsMap = useMemo(
    () =>
      new Map(
        compGridLevels?.map((compGridLevel: any) => [
          compGridLevel.name,
          {
            headerName: compGridLevel.name,
            children: getGridLevelCols(
              compGridLevel.name,
              compGridLevel.rate_fields,
              userRole,
              editable,
              editedCells
            ),
          },
        ])
      ),
    [compGridLevels, editable, editedCells, showDateRanges]
  );

  const levelCols = useMemo(
    () =>
      Array.from(levelColsMap.values()).filter((v) =>
        filteredLevels.includes(v.headerName)
      ),
    [levelColsMap, filteredLevels]
  );

  const levelNames = useMemo(
    () => Array.from(levelColsMap.keys()),
    [levelColsMap]
  );

  useEffect(() => {
    const compGridFilteredValues = searchParams
      .getAll('comp_grids')
      .map(Number);
    if (Array.isArray(compGrids))
      if (compGridFilteredValues.length === 0) {
        setSelectedCompGrids(compGrids.map((compGrid) => compGrid.id));
      } else {
        setSelectedCompGrids(
          compGrids
            .filter((compGrid) => compGridFilteredValues.includes(compGrid.id))
            .map((compGrid) => compGrid.id)
        );
      }

    const levelFilteredValues = searchParams.getAll('levels');
    if (Array.isArray(levelNames))
      if (levelFilteredValues.length > 0) {
        setFilteredLevels(levelFilteredValues);
      } else {
        setFilteredLevels(levelNames);
      }
  }, [isLoadingCompGrids, searchParams, levelNames]);

  const columnDefs = [...columnDefsBase, ...levelCols];

  const compGridsCriteria = filteredCompGrids
    .map((compGrid) => compGrid.comp_grid_criteria)
    .flat();

  const rowList = compGridsCriteria?.filter((datum) => {
    if (!query) return true;
    const searchFields = [
      datum.comp_grid_product.name,
      datum.comp_grid_product.type,
      datum.company.company_name,
      datum.compensation_type,
    ];
    return searchFields.join(' ').toLowerCase().includes(query.toLowerCase());
  });

  const filteredData = rowList?.map((compGridCriterion) => {
    const datum = {
      id: compGridCriterion.id,
      company: compGridCriterion.company?.company_name,
      product_type: compGridCriterion.comp_grid_product?.type,
      product: compGridCriterion.comp_grid_product?.name,
      compensation_type: compGridCriterion.compensation_type,
      issue_age_start: compGridCriterion.issue_age_start,
      issue_age_end: compGridCriterion.issue_age_end,
      premium_min: compGridCriterion.premium_min,
      premium_max: compGridCriterion.premium_max,
      policy_year_start: compGridCriterion.policy_year_start,
      policy_year_end: compGridCriterion.policy_year_end,
      str_id: compGridCriterion.str_id,
    };
    compGridCriterion.comp_grid_rates?.forEach((rate) => {
      const compGridLevel = rate.comp_grid_level.name;
      if (!isProducer) {
        datum[`carrier_rate::${compGridLevel}`] = rate.carrier_rate;
        datum[`house_rate::${compGridLevel}`] = rate.house_rate;
      }
      datum[`rate::${compGridLevel}`] = rate.rate;
      datum[`rate_id::${compGridLevel}`] = rate.id;
    });

    datum['rates_date_ranges'] = [];
    compGridCriterion.comp_grid_rates[0]?.date_ranges?.forEach((dateRange) => {
      const dateRangeName = dateRange.name;
      if (dateRangeName) {
        datum['rates_date_ranges'].push(
          `${dateRangeName}: (${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)})`
        );
      } else {
        datum['rates_date_ranges'].push(
          `${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}`
        );
      }
    });
    return datum;
  });

  // Merge filteredData and editedValues to show edited values
  useEffect(() => {
    const editKeys = Object.keys(editedValues);
    if (filteredData && filteredData.length && editKeys.length) {
      editKeys.forEach((key) => {
        const [rowIndex, _key] = key.split('-');
        const node = filteredData.find(
          (row) => row.id === parseInt(rowIndex, 10)
        );

        if (node) {
          node[_key] = editedValues[key];
        }
      });
    }
    gridRef.current?.api?.refreshCells();
  }, [editedValues, filteredData]);

  const onCellValueChanged = useCallback(
    (params: CellValueChangedEvent) => {
      const { column, data, newValue } = params;
      if (newValue !== params.oldValue) {
        const _key = column.getId();
        const isValid = !isNaN(newValue);
        setEditedCells((prev) => ({
          ...prev,
          [`${data.id}-${_key}`]: isValid ? 'valid' : 'invalid',
        }));
        setEditedValues((prev) => ({
          ...prev,
          [`${data.id}-${_key}`]: newValue,
        }));

        const rowNode = rowList.find((row) => row.id === data.id);

        const [_fieldKey, _levelName] = _key.split('::');

        if (!rowNode) {
          return;
        }
        const rowNodeRates = rowNode.comp_grid_rates.filter((r) => {
          return r.comp_grid_level.name === _levelName;
        });

        const targetNodes = editNode[rowNode.str_id];

        if (targetNodes) {
          const copyNodes = cloneDeep(targetNodes);
          const nodeIndex = copyNodes.findIndex((r) => {
            return r.comp_grid_level.name === _levelName;
          });
          if (nodeIndex > -1) {
            copyNodes[nodeIndex] = rowNodeRates;
          } else {
            copyNodes.push(...rowNodeRates);
          }

          const newNodes = cloneDeep(editNode);
          newNodes[rowNode.str_id] = copyNodes.flat();

          setEditNode(newNodes);
        } else {
          const newNodes = cloneDeep(editNode);
          newNodes[rowNode.str_id] = rowNodeRates.flat();
          setEditNode(newNodes);
        }
      }
    },
    [rowList]
  );

  const onSave = async () => {
    if (!editable) {
      setEditable(true);
      return;
    }
    gridRef.current.api.stopEditing();
    // Prepare data to save
    const changeObj = {};
    const _editedValues = cloneDeep(editedValues);
    let _editNode = cloneDeep(editNode);
    // get the focused cell
    const focusedCell = gridRef.current.api.getFocusedCell();
    if (focusedCell) {
      const { rowIndex, column } = focusedCell;
      const {
        colDef: { field },
      } = column;
      const key = `${rowIndex}-${field}`;
      // get the cell node
      const node = gridRef.current.api.getDisplayedRowAtIndex(rowIndex);
      if (node && _editedValues[key] === undefined) {
        _editedValues[key] = node.data[field];
        // update editedNodes
        const [_fieldKey, _levelName] = field.split('::');
        const rowNode = rowList.find((row) => row.id === node.data.id);
        const rowNodeRates = rowNode.comp_grid_rates.filter((r) => {
          return r.comp_grid_level.name === _levelName;
        });
        const targetNodes = editNode[rowNode.str_id];

        if (targetNodes) {
          const copyNodes = cloneDeep(targetNodes);
          const nodeIndex = copyNodes.findIndex((r) => {
            return r.comp_grid_level.name === _levelName;
          });
          if (nodeIndex > -1) {
            copyNodes[nodeIndex] = rowNodeRates;
          } else {
            copyNodes.push(...rowNodeRates);
          }

          const newNodes = cloneDeep(editNode);
          newNodes[rowNode.str_id] = copyNodes.flat();

          _editNode = newNodes;
        } else {
          const newNodes = cloneDeep(editNode);
          newNodes[rowNode.str_id] = rowNodeRates.flat();
          _editNode = newNodes;
        }
      }
    }

    Object.keys(_editedValues).forEach((key) => {
      const [rowIndex, _key] = key.split('-');
      const [field, level] = _key.split('::');
      const node = filteredData.find(
        (row) => row.id === parseInt(rowIndex, 10)
      );
      if (node) {
        if (!changeObj[node['str_id']]) {
          changeObj[node['str_id']] = {
            [level]: {
              [field]: _editedValues[key],
            },
          };
        } else {
          if (!changeObj[node['str_id']][level]) {
            changeObj[node['str_id']][level] = {
              [field]: _editedValues[key],
            };
          } else {
            changeObj[node['str_id']][level][field] = _editedValues[key];
          }
        }
      }
    });
    // Find rates by str_id
    const changeEntries = Object.entries(changeObj) as [string, any];
    const changedRates: any[] = [];

    for (const [strId, rateItem] of changeEntries) {
      const rowItems = _editNode[strId];
      if (!rowItems) {
        continue;
      }
      for (let rate of rowItems) {
        const rateLevelKey = rate.comp_grid_level.name;
        const tempPreItem = rateItem[rateLevelKey];
        if (tempPreItem) {
          rate = {
            ...rate,
            ...tempPreItem,
          };
          changedRates.push(rate);
        }
      }
    }

    const params = changedRates.map((rate) => {
      return {
        id: rate.id,
        carrier_rate: rate.carrier_rate,
        house_rate: rate.house_rate,
        rate: rate.rate,
        comp_grid_level_id: rate.comp_grid_level_id,
        comp_grid_criterion_id: rate.comp_grid_criterion_id,
        date_ranges: rate.date_ranges,
      };
    });
    if (!params.length) {
      setEditable(false);
      return;
    }

    // Save it to database asynchronously
    setLoading(true);
    try {
      await ratesPatcher.mutateAsync(params);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
    setLoading(false);
    // Toggle editable
    clear();
    // Refetch data
    refetchCompGrids();
  };

  const clear = () => {
    setEditedCells({});
    setEditedValues({});
    setEditable(false);
    setEditNode({});
  };

  const handleRowSelected = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      const levelsSet = new Set<string>();
      const ratesIdsSet = new Set<string>();
      const _selectedCriteria: any = [];
      selectedRows.forEach((row: any, index) => {
        _selectedCriteria[index] = {
          comp_grid_product: { type: row.product_type, name: row.product },
          policy_year_start: row.policy_year_start,
          policy_year_end: row.policy_year_end,
          issue_age_start: row.issue_age_start,
          issue_age_end: row.issue_age_end,
          compensation_type: row.compensation_type,
        };
        Object.keys(row).forEach((key) => {
          const [field, level] = key.split('::');
          if (level) {
            levelsSet.add(level);
          }
          if (field === 'rate_id') {
            ratesIdsSet.add(row[key]);
          }
        });
      });
      setSelectedLevels(Array.from(levelsSet));
      setSelectedRatesIds(Array.from(ratesIdsSet));
      setSelectedCriteria(_selectedCriteria);
    }
  }, [selectedRows]);

  const bulkEditRates = async () => {
    setLoading(true);
    try {
      const params = {
        ids: selectedRatesIds,
        date_ranges: selectedDateRanges.date_ranges,
      };
      const res = await ratesBulkPatcher.mutateAsync(params);
      if (res.error) {
        openSnackbar(
          <Alert severity="error">
            An error ocurred when updating date range: {res.error}
          </Alert>
        );
      } else {
        openSnackbar(<Alert severity="success">Rates updated.</Alert>);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
    setSelectedDateRanges([]);
    setLoading(false);
    clear();
    refetchCompGrids();
    setOpenBulkActionsDialog(false);
  };

  const bulkDeleteRates = async () => {
    setLoading(true);
    try {
      const params = {
        ids: selectedRatesIds,
      };
      const res = await ratesBulkDeleter.mutateAsync(params);
      if (res.error) {
        openSnackbar(
          <Alert severity="error">
            An error ocurred when deleting rates: {res.error}
          </Alert>
        );
      } else {
        openSnackbar(<Alert severity="success">Rates deleted.</Alert>);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
    setSelectedDateRanges([]);
    setLoading(false);
    clear();
    refetchCompGrids();
    setShowConfirmDeleteDialog(false);
  };

  const bulkCopyRates = async () => {
    setLoading(true);
    try {
      const params = {
        ids: selectedRatesIds,
        date_ranges: selectedDateRanges.date_ranges,
      };
      const res = await copyRatesPoster.mutateAsync(params);
      if (res.errors) {
        openSnackbar(
          <Alert severity="error">
            An error ocurred when updating date range: {res.errors[0].error}
          </Alert>
        );
      } else {
        openSnackbar(<Alert severity="success">Rates copied.</Alert>);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
    setSelectedDateRanges([]);
    setLoading(false);
    clear();
    refetchCompGrids();
    setOpenBulkActionsDialog(false);
  };

  // Function to provide a unique ID for each row, this allows to mantain selected rows on re-render
  const getRowId = useMemo(() => (params) => String(params.data.id), []);

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <style>
        {`
          .ag-cell-custom-valid {
            background-color: #eaf5fd !important;
            font-weight: bold;
          }
          .ag-cell-custom-invalid {
            border-color: #f60 !important;
            font-weight: bold;
          }
        `}
      </style>
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">{pageLabel}</Typography>
          <Box sx={{ display: 'flex' }}>
            <MultiSelect
              label="Comp grids"
              values={
                Array.isArray(compGrids)
                  ? compGrids.map((compGrid) => ({
                      id: compGrid.id,
                      name: compGrid.name,
                    }))
                  : []
              }
              filteredValues={selectedCompGrids}
              setFilteredValues={(values) => {
                setSearchParams((prev) => {
                  prev.delete('comp_grids');
                  if (
                    Array.isArray(compGrids) &&
                    compGrids.length !== values.length
                  ) {
                    values.forEach((value) => {
                      prev.append('comp_grids', value);
                    });
                  }
                  return prev;
                });
              }}
              formatter={(value) => value}
              valuer={(value) => value}
              sx={{ width: 180, mr: 1 }}
            />
            <MultiSelect
              label="Levels"
              values={Array.isArray(levelNames) ? levelNames : []}
              filteredValues={filteredLevels}
              setFilteredValues={(values) => {
                setSearchParams((prev) => {
                  prev.delete('levels');
                  values.forEach((value) => {
                    if (
                      Array.isArray(levelNames) &&
                      levelNames.length !== values.length
                    ) {
                      prev.append('levels', value);
                    }
                  });
                  return prev;
                });
              }}
              formatter={(value) => value}
              valuer={(value) => value}
              sx={{ width: 160 }}
            />
            <FormControl sx={{ ml: 1, width: 130 }}>
              <InputLabel>Date</InputLabel>
              <Select
                value={selectedDateFilter}
                label="Date"
                onChange={(e) => setSelectedDateFilter(e.target.value)}
                sx={{
                  '.MuiSelect-select': {
                    py: 0.75,
                    px: 1.5,
                  },
                }}
              >
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="singleDate">Date</MenuItem>
                <MenuItem value="dateRange">Date range</MenuItem>
              </Select>
            </FormControl>
            {selectedDateFilter === 'singleDate' && (
              <BasicDatePicker
                label="Effective date"
                value={effectiveDateFilterValue}
                setValue={(e) => setEffectiveDateFilterValue(e)}
                sx={{ ml: 1, width: 160 }}
              />
            )}
            {selectedDateFilter === 'dateRange' && (
              <>
                <FormControl sx={{ ml: 1, mr: 1, width: 145 }}>
                  <InputLabel sx={{ lineHeight: 1 }}>Date range</InputLabel>
                  <Select
                    value={dateRangeFilterValue ?? ''}
                    label="Date range"
                    onChange={(e) => {
                      setDateRangeFilterValue(
                        parseInt(e.target.value as string)
                      );
                    }}
                    sx={{
                      '.MuiSelect-select': { py: 0.75, px: 1.5 },
                    }}
                  >
                    {dateRangesData?.map((dateRange: DateRange) => (
                      <MenuItem key={dateRange.id} value={dateRange.id}>
                        {dateRange.name
                          ? `${dateRange.name}: ${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}`
                          : `${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </Box>
          <Box>
            <Box sx={{ display: 'flex' }}>
              <SearchBox />
              <SearchSettings settings={searchSettings} />
              {editable && (
                <>
                  <Button
                    sx={{ ml: 3 }}
                    variant="outlined"
                    disabled={selectedRows.length === 0}
                    onClick={() => setOpenBulkActionsDialog(true)}
                  >
                    Bulk actions
                  </Button>
                  <Button sx={{ ml: 1 }} onClick={clear}>
                    Cancel
                  </Button>
                </>
              )}
              <LoadingButton
                variant="contained"
                sx={{ ml: 1 }}
                onClick={onSave}
                loading={loading}
              >
                {editable ? 'Save' : 'Edit'}
              </LoadingButton>
            </Box>
            {editable && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  mt: 1,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    backgroundColor: '#ffffaa',
                    p: 0.5,
                    pb: 0.25,
                    lineHeight: 'unset',
                    borderRadius: 2,
                  }}
                >
                  ℹ️ Click rates to edit
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ flex: 1 }} className="ag-theme-material">
        {!isLoadingCompGrids ? (
          <AgGridReact
            headerHeight={40}
            columnDefs={columnDefs}
            rowData={filteredData}
            suppressHorizontalScroll={false}
            alwaysShowHorizontalScroll={true}
            debounceVerticalScrollbar={true}
            ref={gridRef}
            onCellValueChanged={onCellValueChanged}
            singleClickEdit={true}
            tooltipShowDelay={500}
            rowSelection={editable ? 'multiple' : undefined}
            onRowSelected={handleRowSelected}
            suppressRowClickSelection={true}
            getRowId={getRowId}
          />
        ) : (
          <LoadingCircle />
        )}
      </Box>
      <CompGridBulkActions
        openBulkActionsDialog={openBulkActionsDialog}
        setOpenBulkActionsDialog={setOpenBulkActionsDialog}
        selectedCriteria={selectedCriteria}
        selectedLevels={selectedLevels}
        selectedDateRanges={selectedDateRanges}
        setSelectedDateRanges={setSelectedDateRanges}
        loading={loading}
        bulkEditRates={bulkEditRates}
        bulkCopyRates={bulkCopyRates}
        bulkDeleteRates={bulkDeleteRates}
        showConfirmDeleteDialog={showConfirmDeleteDialog}
        setShowConfirmDeleteDialog={setShowConfirmDeleteDialog}
      />
    </Box>
  );
};

export default CompGridsView;
