import { MoreVert } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useGate } from 'statsig-react';

import BasicTable from '@/components/molecules/BasicTable';
import { exportCsv } from '@/services/helpers';

interface WidgetWrapperProps {
  displayName: string;
  onEdit?: () => void;
  onDelete?: () => void;
  children: React.ReactNode;
  enableCustomWidget: boolean;
  isCustomWidget?: boolean;
  sharedWidget?: boolean;
  data?: any;
  type?: string;
}

const WidgetWrapper = ({
  displayName,
  onEdit,
  onDelete,
  sharedWidget,
  enableCustomWidget,
  isCustomWidget,
  children,
  data: _data,
  type,
}: WidgetWrapperProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [view, setView] = useState('widget');
  const { value: superAdmin } = useGate('widgetsuperadmin');

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const hideMenu =
    !isCustomWidget &&
    !(typeof type === 'string' && ['chart-donut', 'chart-bar'].includes(type));

  let headers;
  let data;
  if (type === 'chart-donut') {
    headers = Object.keys(_data?.series?.[0]?.data?.[0] || {});
    data = _data?.series?.[0]?.data?.map((row) => {
      return Object.values(row);
    });
  } else if (type === 'chart-bar') {
    headers = ['key', 'value'];
    data = _data?.xAxis.data.map((key, i) => [
      key,
      _data?.series?.[0].data?.[i],
    ]);
  }

  return (
    <Card>
      <CardContent sx={{ pb: 0 }}>
        <Grid container justifyContent="space-between">
          <Grid item style={{ width: '80%' }}>
            <Typography gutterBottom variant="body1">
              {displayName}
            </Typography>
          </Grid>

          <Grid item style={{ display: hideMenu ? 'none' : 'inherit' }}>
            <IconButton onClick={handleMenuClick}>
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {isCustomWidget &&
                ((enableCustomWidget && !sharedWidget) || superAdmin) && [
                  <MenuItem
                    key="edit"
                    onClick={() => {
                      handleMenuClose();
                      onEdit && onEdit();
                    }}
                  >
                    Edit
                  </MenuItem>,
                  <MenuItem
                    key="delete"
                    onClick={() => {
                      handleMenuClose();
                      onDelete && onDelete();
                    }}
                  >
                    Delete
                  </MenuItem>,
                ]}

              {typeof type === 'string' &&
                ['chart-donut', 'chart-bar'].includes(type) && [
                  <MenuItem
                    key="toggleView"
                    onClick={() => {
                      setView(view === 'table' ? 'widget' : 'table');
                    }}
                  >
                    {view === 'table' ? 'Widget view' : 'Table view'}
                  </MenuItem>,
                  <MenuItem
                    key="export"
                    onClick={() => {
                      exportCsv(headers, data, 'Fintary-Export.csv');
                    }}
                  >
                    Export
                  </MenuItem>,
                ]}
            </Menu>
          </Grid>
        </Grid>
        {view === 'widget' && children}
        {view === 'table' && (
          <BasicTable headers={headers} rows={data} formatters={{}} />
        )}
      </CardContent>
    </Card>
  );
};

export default WidgetWrapper;
